import React, { useState } from "react"
import * as styles from "./component.module.less"
import logo from "../../../static/assets/logo.svg"
import * as service from "../../services/auth"
import { navigate } from "gatsby"

import ClipLoader from "react-spinners/ClipLoader"

const LoginLayout = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  let [loading, setLoading] = useState(false)

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      login()
    }
  }

  const login = () => {
    if (username.lenght !== 0 && password.length !== 0) {
      setLoading(true)
      service
        .handleLogin(username, password)
        .then(res => {
          setError(false)
          if (service.isBrowser()) {
            navigate("/")
          }
        })
        .catch(e => {
          setError(true)
          setLoading(false)
        })
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.head}>
          <img src={logo} className={styles.logo} />
        </div>

        {loading && (
          <div className={styles.spinnerContainer}>
            <ClipLoader
              color={"#0078b9"}
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}

        {!loading && (
          <div className={styles.loginBox}>
            <span className={styles.headline}>Login</span>
            <label className={error ? styles.errorText : {}}>Nutzername</label>
            <input
              className={error ? styles.error : {}}
              placeholder="Geben Sie bitte Ihren Nutzernamen ein"
              value={username}
              onChange={e => {
                setUsername(e.target.value)
              }}
            />
            <label className={error ? styles.errorText : {}}>Passwort</label>
            <input
              className={error ? styles.error : {}}
              placeholder="Geben Sie bitte Ihr Passwort ein"
              type={"password"}
              value={password}
              onChange={e => {
                setPassword(e.target.value)
              }}
              onKeyPress={handleKeyPress}
            />
            {error && (
              <span className={styles.errorText}>
                Passwort oder Nutzername sind falsch
              </span>
            )}
            <button
              onClick={() => {
                login()
              }}
            >
              Anmelden
            </button>
          </div>
        )}
      </div>

      <div className={styles.right}>
        <div className={styles.image}></div>
      </div>
    </div>
  )
}

export default LoginLayout
