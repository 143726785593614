// extracted by mini-css-extract-plugin
export var container = "component-module--container--2-RzE";
export var left = "component-module--left--3AtY1";
export var head = "component-module--head--1tn9N";
export var logo = "component-module--logo--1M7G5";
export var button = "component-module--button--33W_o";
export var spinnerContainer = "component-module--spinnerContainer--2bbd6";
export var loginBox = "component-module--loginBox--WMI7I";
export var headline = "component-module--headline--2fkRd";
export var error = "component-module--error--5y3J0";
export var errorText = "component-module--error-text--2ZzkA";
export var right = "component-module--right--3Lpg-";
export var image = "component-module--image--1Tvm_";